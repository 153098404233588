<template>
  <div class="d-inline-flex">
    <v-text-field
      solo
      hide-details
      height="16"
      single-line
      :label="$t('actions.search')"
      @keyup.enter="searchFicheTicket()"
      class="ml-4 mr-2"
      v-model.trim="m_searchTicket"
    ></v-text-field>
    <v-btn
      small
      color="primary"
      :disabled="m_searchTicket == ''"
      @click="searchFicheTicket()"
    >
      <v-icon>mdi-magnify</v-icon>
    </v-btn>
    <v-btn
      v-if="filterByTicket"
      small
      color="amber"
      @click="searchFicheTicket('clear')"
      dark
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </div>
</template>

<script>
import HotlineBus from "@/Components/Views/Hotline/HotlineBus";

export default {
  name: "SearchTicket",
  props: {
    filterByTicket: Boolean,
    searchTicket: String,
  },
  data() {
    return {
      m_searchTicket: ""
    }
  },
  methods: {
    searchFicheTicket(clear, enter = false) {
      HotlineBus.$emit("search_fiche_ticket", {
        clear,
        enter,
        searchTicket: this.m_searchTicket,
      });
      if (clear == "clear") {
        this.m_searchTicket = ""
      }
    },
  },
  mounted() {
    this.m_searchTicket = this.searchTicket
  }
};
</script>

<style lang="scss"></style>
